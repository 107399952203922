@import "../../styles/responsive.scss";


.pieChartBox {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h1{
    @include xxl{
      font-size: 24px;
    }
  }

  .chart {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .options {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    font-size: 14px;

    .option {
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: center;

      .title {
        display: flex;
        gap: 10px;
        align-items: center;

        .dot {
          width: 10px;
          height: 10px;
          border-radius: 50%;
        }
      }
    }
  }
}
